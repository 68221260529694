import React, { useContext, useEffect, useState } from "react";
import { SimpleContext } from "../../store/context/simple-context";
import WSController from "../../controllers/authorization/wsController";
import Progress from "../../components/authorization/Progress";
import PropTypes from "prop-types";
import { log } from "../../helpers";

const ProgressContainer = ({ onSuccess }) => {
  const { software } = useContext(SimpleContext);
  const [seconds, setSeconds] = useState(2);
  const [isTestAComplete, setIsTestAComplete] = useState(false);

  useEffect(() => {
    const ws = new WSController();

    ws.sendWSMessage()
      .then((result) => {
        log(result, "testA");
        setIsTestAComplete(true);
      })
      .catch((error) => {
        log(error, "testA", false, true);
        setIsTestAComplete(true);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      } else {
        isTestAComplete && onSuccess();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [seconds, isTestAComplete, onSuccess]);

  const softwareText = (software) => {
    switch (software) {
      case "SCOUT_EXT_PRO":
        return " PRO";
      case "SCOUT_EXT_PRO_NEW":
        return " PRO AI";
      default:
        return "";
    }
  };

  return <Progress seconds={seconds} softwareText={softwareText(software)} />;
};

ProgressContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default ProgressContainer;
