import { getA } from "../../helpers";

class WSController {
  constructor() {
    this.SW_SCOPE = `/sw_${Math.random().toString(36).substr(2, 8)}/`;
    this.SW_URL = "/ws.js";
    this.registration = null;
    this.messageChannel = null;
    this.requestId = 0;
    this.pendingRequests = new Map();
    this.WS_TIMEOUT = 5000; // Вынесено в константу класса
  }

  async _init() {
    if ("serviceWorker" in navigator) {
      // Удаляем ВСЕ предыдущие регистрации
      const registrations = await navigator.serviceWorker.getRegistrations();
      await Promise.all(registrations.map((r) => r.unregister()));

      this.registration = await navigator.serviceWorker.register(this.SW_URL, {
        scope: this.SW_SCOPE,
        updateViaCache: "none",
      });

      // Ожидание активации с таймаутом
      await Promise.race([
        new Promise((resolve) => {
          const interval = setInterval(() => {
            if (this.registration.active) {
              clearInterval(interval);
              resolve();
            }
          }, 50);
        }),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("SW activation timeout")), 2000)
        ),
      ]);
    }
  }

  async sendWSMessage(customData = null) {
    if (!this.registration) await this._init();

    return new Promise((resolve, reject) => {
      const id = ++this.requestId;
      const timeoutId = setTimeout(() => {
        this.pendingRequests.delete(id);
        this._cleanup();
        reject(new Error("WS timeout"));
      }, this.WS_TIMEOUT);

      this.pendingRequests.set(id, {
        resolve,
        reject,
        cleanup: () => clearTimeout(timeoutId),
      });

      this.messageChannel = new MessageChannel();

      this.messageChannel.port1.onmessage = (event) => {
        if (event.data.id === id) {
          this.pendingRequests.get(id)?.cleanup();
          this._cleanup();
          event.data.error
            ? reject(event.data.error)
            : resolve(event.data.response);
        }
      };

      // Отправляем как текстовые данные (getA() уже зашифрован)
      this.registration.active.postMessage(
        {
          type: "ws_request",
          id,
          payload: customData || getA(), // payload вместо encryptedData
          timestamp: Date.now(),
        },
        [this.messageChannel.port2]
      );
    });
  }

  async _cleanup() {
    // Закрываем каналы
    if (this.messageChannel) {
      this.messageChannel.port1.close();
      this.messageChannel = null;
    }

    // Отправляем команду на самоуничтожение
    if (this.registration?.active) {
      try {
        const tempChannel = new MessageChannel();
        tempChannel.port1.close();
        this.registration.active.postMessage({ type: "self_destruct" }, [
          tempChannel.port2,
        ]);
      } catch {
        /* empty */
      }
    }

    // Удаляем регистрацию
    try {
      await this.registration?.unregister();
    } catch {
      /* empty */
    }
    this.registration = null;

    // Очищаем pending запросы
    this.pendingRequests.forEach((req) => {
      req.cleanup();
      req.reject(new Error("Connection terminated"));
    });
    this.pendingRequests.clear();
  }

  // Метод для проверки
  async isWorkerDestroyed() {
    const regs = await navigator.serviceWorker.getRegistrations();
    return regs.length === 0;
  }
}

export default WSController;

// import { getA, log } from "../../helpers";
//
// class WSController {
//   constructor() {
//     // Генерируем уникальную scope для каждого экземпляра
//     this.SW_SCOPE = `/sw_temp_${Date.now()}_${Math.random().toString(36).substr(2, 5)}/`;
//     this.SW_URL = "/ws.js";
//     this.registration = null;
//     this.broadcastChannel = new BroadcastChannel("ws_channel");
//     this.requestId = 0;
//     this.pendingRequests = new Map();
//   }
//
//   async _init() {
//     if ("serviceWorker" in navigator) {
//       try {
//         // Удаляем все предыдущие регистрации
//         const registrations = await navigator.serviceWorker.getRegistrations();
//         await Promise.all(registrations.map(r => r.unregister()));
//
//         // Регистрируем новый SW с уникальной scope
//         this.registration = await navigator.serviceWorker.register(
//           this.SW_URL,
//           {
//             scope: this.SW_SCOPE,
//             updateViaCache: "none"
//           }
//         );
//
//         // Ждём активации
//         await new Promise((resolve) => {
//           const checkActive = () => {
//             if (this.registration.active) {
//               resolve();
//             } else {
//               setTimeout(checkActive, 50);
//             }
//           };
//           checkActive();
//         });
//
//         // Настраиваем обработчик ответов
//         this.broadcastChannel.onmessage = (event) => {
//           if (event.data.type === "ws_response") {
//             const { id, error, response } = event.data;
//             const pending = this.pendingRequests.get(id);
//             if (pending) {
//               error ? pending.reject(error) : pending.resolve(response);
//               this.pendingRequests.delete(id);
//
//               // Автоматическая очистка после получения ответа
//               this._cleanup();
//             }
//           }
//         };
//       } catch (error) {
//         console.error("Service Worker registration failed:", error);
//         await this._cleanup();
//         throw error;
//       }
//     }
//   }
//
//   async sendWSMessage(customData = null) {
//     if (!this.registration) {
//       await this._init();
//     }
//
//     return new Promise((resolve, reject) => {
//       const id = ++this.requestId;
//       this.pendingRequests.set(id, { resolve, reject });
//
//       // Отправляем сообщение
//       this.broadcastChannel.postMessage({
//         type: "ws_request",
//         id,
//         wsHost: process.env.AUTH_WS_HOST,
//         data: customData || getA(),
//         timestamp: Date.now(),
//       });
//
//       // Таймаут
//       setTimeout(() => {
//         if (this.pendingRequests.has(id)) {
//           this.pendingRequests.delete(id);
//           this._cleanup();
//           reject(new Error("Timeout waiting for Service Worker response"));
//         }
//       }, 5000);
//     });
//   }
//
//   async _cleanup() {
//     try {
//       // Закрываем канал
//       if (this.broadcastChannel) {
//         this.broadcastChannel.close();
//       }
//
//       // Отправляем команду на самоуничтожение в SW
//       if (this.registration?.active) {
//         const tempChannel = new MessageChannel();
//         this.registration.active.postMessage({
//           type: "self_destruct"
//         }, [tempChannel.port2]);
//         tempChannel.port1.close();
//       }
//
//       // Удаляем регистрацию
//       if (this.registration) {
//         await this.registration.unregister();
//       }
//
//       // Очищаем pending requests
//       this.pendingRequests.forEach(req => req.reject(new Error("Service Worker terminated")));
//       this.pendingRequests.clear();
//     } catch (error) {
//       console.error("Cleanup error:", error);
//     } finally {
//       this.registration = null;
//     }
//   }
// }
//
// export default WSController;

// import { getA, log } from "../../helpers";
//
// class WSController {
//   constructor() {
//     this.SW_SCOPE = "/";
//     this.SW_URL = "/ws.js";
//     this.registration = null;
//     this.broadcastChannel = new BroadcastChannel("ws_channel");
//     this.requestId = 0;
//     this.pendingRequests = new Map();
//   }
//
//   async _init() {
//     if ("serviceWorker" in navigator) {
//       try {
//         this.registration = await navigator.serviceWorker.register(
//           this.SW_URL,
//           { scope: this.SW_SCOPE, updateViaCache: "none" }
//         );
//         await navigator.serviceWorker.ready;
//
//         // Обработчик ответов от SW через BroadcastChannel
//         this.broadcastChannel.onmessage = (event) => {
//           if (event.data.type === "ws_response") {
//             const { id, error, response } = event.data;
//             const pending = this.pendingRequests.get(id);
//             if (pending) {
//               error ? pending.reject(error) : pending.resolve(response);
//               this.pendingRequests.delete(id);
//             }
//           }
//         };
//       } catch (error) {
//         console.error("Service Worker registration failed:", error);
//       }
//     }
//   }
//
//   async sendWSMessage(customData = null) {
//     if (!this.registration) {
//       await this._init();
//     }
//
//     if (!this.registration?.active) {
//       throw new Error("Service Worker not active");
//     }
//
//     return new Promise((resolve, reject) => {
//       const id = ++this.requestId;
//       this.pendingRequests.set(id, { resolve, reject });
//
//       // Отправляем сообщение через BroadcastChannel
//       this.broadcastChannel.postMessage({
//         type: "ws_request",
//         id,
//         wsHost: process.env.AUTH_WS_HOST,
//         data: customData || getA(),
//         timestamp: Date.now(),
//       });
//
//       // Таймаут на случай если SW не ответит
//       setTimeout(() => {
//         if (this.pendingRequests.has(id)) {
//           this.pendingRequests.delete(id);
//           reject(new Error("Timeout waiting for Service Worker response"));
//         }
//       }, 5000);
//     });
//   }
// }
//
// export default WSController;