import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SimpleContext } from "../../../store/context/simple-context";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Progress = ({ seconds, softwareText }) => {
  const { translate: t, isCn, isSignUp } = useContext(SimpleContext);
  const text = t(`You will be redirected to AMZScout${softwareText} in`);
  const sec = t("seconds");

  const titleText = isCn
    ? `${seconds} ${text} ${sec}`
    : `${text} ${seconds} ${sec}`;

  return (
    <div
      className={getTrueClasses(
        "PgAuth-Progress",
        !isSignUp && "PgAuth-Progress_small"
      )}
    >
      {isSignUp && (
        <p className="PgAuth-Progress__title">
          {t("We’ve sent the password to your email address")}
        </p>
      )}
      <div
        className={getTrueClasses(
          "PgAuth-Progress-progress",
          !isSignUp && "PgAuth-Progress-progress_small"
        )}
      >
        <div className="PgAuth-Progress-progress__line" />
      </div>
      <p className="PgAuth-Progress__title">{titleText}</p>
    </div>
  );
};

Progress.propTypes = {
  seconds: PropTypes.number.isRequired,
  softwareText: PropTypes.string.isRequired,
};

export default Progress;
