import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { graphql } from "gatsby";
import { MediaAuthContext } from "../store/context/media-auth-context";
import { SimpleContext } from "../store/context/simple-context";
import { ERRORS } from "../helpers/errors";
import { ANALYTICS_PARAMS } from "../components/authorization/index.content";
import AuthorizationController from "../controllers/authorization/authorizationController";
import AnalyticsController from "../controllers/authorization/analyticsController";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Seo from "../components/Seo";
import Form from "../components/authorization/Form";
import Layout from "../layouts/LayoutEmpty";
import SignContainer from "../containers/authorization/SignContainer";
import SocialContainer from "../containers/authorization/SocialContainer";
import ProgressContainer from "../containers/authorization/ProgressContainer";
import Message from "../components/authorization/Message";
import RestoreContainer from "../containers/authorization/RestoreContainer";
import Loader from "../components/authorization/Loader";
import { log } from "../helpers";
import Reviews from "../components/authorization/Reviews";
import "../components/authorization/index.scss";

const authorizationController = new AuthorizationController();
const analyticsController =
  typeof window !== "undefined" ? new AnalyticsController() : {};

const Authorization = ({ pageContext }) => {
  const language = pageContext.i18n.language;
  const { t } = useTranslation();
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);
  const [getResponse, setResponse] = useState({});
  const [getParams, setParams] = useState({
    software: "",
    returnUrl: "",
  });

  const [formState, setFormState] = useState({
    hash: "",
    loaderMode: false,
    passwordMode: false,
    isSignUp: true,
    restoreState: false,
    redirectState: false,
    isLoading: false,
    auth: {
      email: "",
      error: "",
      password: "",
      confirmationPassword: "",
      passwordFieldType: "password",
      btnIsActive: true,
    },
    message: {
      display: false,
      status: false,
      error: false,
    },
  });

  const updateFormState = useCallback((args) => {
    setFormState((prevState) => ({
      ...prevState,
      ...args,
    }));
  }, []);

  const updateMessageDisplay = useCallback((args) => {
    setFormState((prevState) => ({
      ...prevState,
      message: {
        ...prevState.message,
        ...args,
      },
    }));
  }, []);

  const updateAuthState = useCallback((args) => {
    setFormState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        ...args,
      },
    }));
  }, []);

  const handleSuccessAuth = useCallback(() => {
    return authorizationController.handleSuccessAuth(getParams, getResponse);
  }, [getParams, getResponse]);

  const onRegistrationSuccess = useCallback(
    (res) => {
      if (!res.isNew) {
        updateFormState({ isSignUp: false });
      }
      setResponse({ ...res });
      updateFormState({ redirectState: true });
    },
    [updateFormState]
  );

  const sendToAnalyticsShowEvent = useCallback(
    (authStates) => {
      analyticsController.sendToAnalyticsEvent(
        authStates,
        ANALYTICS_PARAMS.ACTIONS.SHOW,
        ANALYTICS_PARAMS.LABEL.EMPTY,
        true
      );
    },
    [analyticsController]
  );

  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        const { software, url, hash, state } = Object.fromEntries(
          new URL(window.location.href).searchParams
        );
        setParams({ software, returnUrl: url });
        hash && updateFormState({ hash, loaderMode: true });
        state === "login" && updateFormState({ isSignUp: false });
      }
    } catch (error) {
      log(error, ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS, true);
    } finally {
      updateFormState({ isLoading: true });
    }
  }, [updateFormState]);

  useEffect(() => {
    if (
      !formState.loaderMode &&
      !formState.restoreState &&
      !formState.passwordMode &&
      formState.isLoading
    ) {
      sendToAnalyticsShowEvent(
        formState.isSignUp
          ? ANALYTICS_PARAMS.AUTH_STATES.SIGN_UP_FORM
          : ANALYTICS_PARAMS.AUTH_STATES.SIGN_IN_FORM
      );
    }
  }, [formState.isSignUp, formState.isLoading, formState.restoreState]);

  useEffect(() => {
    if (formState.restoreState && formState.isLoading) {
      sendToAnalyticsShowEvent(
        formState.passwordMode
          ? ANALYTICS_PARAMS.AUTH_STATES.NEW_PASSWORD
          : ANALYTICS_PARAMS.AUTH_STATES.FORGOT_PASSWORD
      );
    }
  }, [formState.restoreState, formState.passwordMode, formState.isLoading]);

  return (
    <SimpleContext.Provider
      value={{
        translate: t,
        software: getParams.software,
        isCn: language === "zh",
        withTabs:
          !formState.restoreState &&
          !formState.redirectState &&
          !formState.message.display &&
          !formState.loaderMode,
        isSignUp: formState.isSignUp,
        message: formState.message,
        hash: formState.hash,
        auth: formState.auth,
        passwordMode: formState.passwordMode,
        updateFormState,
        updateMessageDisplay,
        updateAuthState,
        sendToAnalyticsEvent: analyticsController.sendToAnalyticsEvent,
      }}
    >
      <GoogleOAuthProvider clientId={process.env.AUTH_GOOGLE_ID}>
        <Layout className="PgAuth-Page" isShowHelp>
          <Seo
            title={t(
              formState.restoreState
                ? "Reset Password"
                : `Sign ${formState.isSignUp ? "up" : "in"}`
            )}
            description=""
            page=""
            manifest="browserconfig-index.xml"
            ogImg={OgImg}
            // trustpilotWidget
          />
          <div className="PgAuth-content">
            <Form>
              {formState.loaderMode ? (
                <Loader />
              ) : formState.message.display ? (
                <Message />
              ) : !formState.restoreState ? (
                !formState.redirectState ? (
                  <>
                    <SignContainer onSuccess={onRegistrationSuccess} />
                    <SocialContainer onSuccess={onRegistrationSuccess} />
                  </>
                ) : (
                  <ProgressContainer onSuccess={handleSuccessAuth} />
                )
              ) : (
                <RestoreContainer />
              )}
            </Form>
            {!isNotInitMobile && <Reviews />}
          </div>
        </Layout>
      </GoogleOAuthProvider>
    </SimpleContext.Provider>
  );
};

export default Authorization;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "authorization" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
