class SignModel {
  constructor() {
    this.requestHeaders = {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
    };
  }

  register(email, software) {
    return fetch(
      process.env.AUTH_HOST +
        "/v2/auth/registration" +
        `?email=${encodeURIComponent(email)}` +
        `&software=${encodeURIComponent(software)}`,
      {
        headers: this.requestHeaders,
        method: "POST",
      }
    );
  }

  login(email, password) {
    return fetch(
      process.env.AUTH_HOST +
        "/v1/auth/signin" +
        `?email=${encodeURIComponent(email)}` +
        `&password=${encodeURIComponent(password)}`,
      {
        headers: this.requestHeaders,
        method: "POST",
      }
    );
  }
}

export default SignModel;
